import React from 'react';
import Berger from '../assets/berger.png';
import BergerLogo from '../assets/berger-logo.png';

import '../CSS/ComingSoon.css';
function ComingSoon() {
  return (
    <div className='imageBergerDiv'>
        <div className='imgDivBergerOuter'>
            <div className='imgDivBerger'>
                <img loading="lazy" src={Berger} className='imageBerger'/>
            </div>
            <div className='comingSoonTextDiv'>
                <h2 className='comingSoonText'>
                    Coming Soon...
                </h2>
                <img loading='lazy' src={BergerLogo} className='bergerLogo'/>
            </div>
        </div>
    </div>
  )
}

export default ComingSoon